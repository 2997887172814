@charset "UTF-8";
/* CSS Document */

html {
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.postpost {
  display: none;
}
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #4c6571;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  /* text-transform: uppercase; */
  font-weight: 700;
  letter-spacing: 0.02em;
}
h2.sectionTitle {
  color: rgba(28, 98, 124, 0.5);
  padding: 1rem 1.5rem;
  margin: 2rem 0 1rem;
  display: inline-block;
  border-top: solid 2px rgba(28, 98, 124, 0.25);
  border-bottom: solid 2px rgba(28, 98, 124, 0.25);
}
@media (min-width: 768px) {
  h2.sectionTitle {
    margin: 6rem 0 3rem;
  }
}

h2.sectionTitle span {
  display: inline-block;
}
#countdown {
  font-weight: 400;
  font-size: 48px;
  color: #1c627c;
}
#bottom-hero {
  margin-bottom: 0px;
  margin-top: 60px;
}
header {
  padding: 0.5rem 0;
  text-align: center;
  background-color: #ffffff;
}
.logo {
  width: 100px;
  height: auto;
}
@media (min-width: 768px) {
  header {
    padding: 1rem 0;
  }
  .logo {
    width: 200px;
  }
}
.footer {
  font-weight: 400;
  padding: 100px 15px;
  font-size: 18px;
  color: #bce6e4;
  background-color: #185f7b;
  margin-bottom: 0px;
  margin-top: 60px;
  background: radial-gradient(
      rgba(100, 150, 200, 0.5),
      rgba(28, 98, 124, 0.3) 50vh
    ),
    url("images/tealBack5.jpg") no-repeat center center;
  background-size: cover;
  background-attachment: scroll;
}
@media (min-width: 768px) {
  .footer {
    background-attachment: fixed;
    font-size: 24px;
  }
}
.footer span {
  margin-right: 5px;
}
.hero {
  background-color: #185f7b;
  background: radial-gradient(
      rgba(100, 150, 200, 0.5),
      rgba(28, 98, 124, 0.3) 50vh
    ),
    url("images/tealBack5.jpg") no-repeat center center;
  background-size: cover;
  background-attachment: scroll;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 400;
  padding: 100px 15px;
  font-size: 18px;
  color: #bce6e4;
  margin-bottom: 30px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
}
@media (min-width: 768px) {
  .hero {
    background-attachment: scroll, fixed;
    font-size: 24px;
  }
}
.myBigName {
  font-size: 2em;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: white;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.01em;
}
@media (min-width: 768px) {
  .myBigName {
    font-size: 3em;
  }
}
.dimage img {
  width: 100%;
  max-width: 700px;
  height: auto;
}

div.dimage {
  padding: 15px 30px;
}
div.dimage--glass {
  padding: 0;
}
@media (min-width: 768px) {
  div.dimage--glass {
    margin-top: 1.5rem;
  }
}
.siteDesc {
  text-align: left;
}
.siteDesc h3 {
  font-size: 2em;
  color: #1c627c;
  /* font-weight: 600; */
  margin-top: -10px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .siteDesc h3 {
    margin-top: 60px;
  }
}
.siteDesc h4 {
  font-size: 1.2em;
  color: #1c627c;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
#herotext {
  text-align: center;
}
#hero-text2 {
  max-width: 800px;
  margin: 0 auto;
}
a.btn-teal {
  font-family: "Montserrat", sans-serif;
  border-color: #1c627c;
  color: #1c627c;
  border-width: 2px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
  letter-spacing: 0.03em;
}
a.btn-teal:hover {
  background-color: #1c627c;
  color: white;
}
a {
  /* Firefox */
  -moz-transition: all 0.3s ease-out; /* WebKit */
  -webkit-transition: all 0.3s ease-out;
  /* Opera */
  -o-transition: all 0.3s ease-out;
  /* Standard */
  transition: all 0.3s ease-out;
}
a.linkOnDark {
  color: white;
}
a.linkOnDark:hover {
  text-decoration: none;
  color: #bce6e4;
}
.hero a.btn {
  border-color: white;
  color: white;
  border-width: 3px;
  padding: 15px 26px;
  font-size: 1em;
  font-weight: 600;
  margin-top: 30px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  border-radius: 1px;
}
.hero a.btn span {
  font-size: 0.85em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero a.btn span i {
  margin-right: 0.5rem;
}
.hero a.btn:hover {
  background-color: rgba(188, 230, 228, 0.5);
}
.siteDesc p {
  margin: 1.5rem 0;
  line-height: 1.6em;
}
.siteDesc li {
  margin-top: 10px;
}
.siteDesc ul {
  padding-left: 2rem;
}
.spacer {
  height: 2px;
  background-color: rgba(28, 98, 124, 0.25);
  margin: 3.5rem 0;
}
section {
  border-bottom: 2px solid rgba(28, 98, 124, 0.25);
  padding-bottom: 3.5rem;
  margin-bottom: 3.5rem;
}
.spacer:last-child {
  height: 1px;
  margin-bottom: 3rem;
  margin-top: 0;
  background-color: transparent;
}
@media (min-width: 768px) {
  .spacer:last-child {
    margin-bottom: 1rem;
  }
}
.success {
  padding: 1em;
  margin-bottom: 0.75rem;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  color: #468847;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.error {
  padding: 1em;
  margin-bottom: 0.75rem;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  color: #b94a48;
  background-color: #f2dede;
  border: 1px solid rgba(185, 74, 72, 0.3);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.wrapper {
  -webkit-transition: all 800ms cubic-bezier(0.865, -0.15, 0.085, 1.21);
  -moz-transition: all 800ms cubic-bezier(0.865, -0.15, 0.085, 1.21);
  -ms-transition: all 800ms cubic-bezier(0.865, -0.15, 0.085, 1.21);
  -o-transition: all 800ms cubic-bezier(0.865, -0.15, 0.085, 1.21);
  transition: all 800ms cubic-bezier(0.865, -0.15, 0.085, 1.21);
  -webkit-transition-timing-function: cubic-bezier(0.865, -0.15, 0.085, 1.21);
  -moz-transition-timing-function: cubic-bezier(0.865, -0.15, 0.085, 1.21);
  -ms-transition-timing-function: cubic-bezier(0.865, -0.15, 0.085, 1.21);
  -o-transition-timing-function: cubic-bezier(0.865, -0.15, 0.085, 1.21);
  transition-timing-function: cubic-bezier(0.865, -0.15, 0.085, 1.21);
  -moz-perspective: 1000;
  -webkit-perspective: 1000;
  perspective: 1000;
  transform-origin: center 5%;
}
body.inContact {
  overflow: hidden;
}
body.inContact div.wrapper {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
.modal2 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: 50%;
  padding: 20px;
  background: red;
  color: white;
  text-align: center;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}
.close {
  text-align: right;
}
.modal-content {
  background-color: rgba(222, 230, 243, 0.87);
}
.modal-content .form-group {
  text-align: left;
}
h2.modal-title {
  font-weight: 700;
  color: white;
}
.modal-header {
  background-color: rgba(28, 98, 124, 0.9);
}
.btn-primary {
  background-color: rgba(52, 141, 173, 1);
  border-color: rgba(29, 101, 128, 1);
}
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
  background-color: #1c627c;
  border-color: #2e6da4;
}
.modal-backdrop {
  background-color: rgba(44, 75, 90, 1);
}
.loading-boundary {
  position: relative;
}
.loading {
  display: none;
  position: absolute;
  opacity: 0.8;
  /* Some subtle opacity to help blend with variable background colors */
  background: url("/update/ajax-loader.gif") center center no-repeat,
    rgba(255, 255, 255, 1);
  width: 100%;
  height: 100%;
  z-index: 2000;
}
.table {
  height: 100%;
  width: 100%;
  display: table;
  z-index: 6000;
}
.table-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 100%;
}
#contactAlt {
  padding: 6px 0;
  float: none;
  margin-top: 14px;
}
@media (min-width: 768px) {
  #contactAlt {
    float: left;
    margin-top: 0;
  }
}
.youTubeCont {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 0;
  height: 0;
  margin: 60px 0;
}
.youTubeCont iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.lds-ellipsis {
  position: relative;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  margin-bottom: -64px;
  z-index: 10;
  transition: opacity 0.5s;
  transition-property: height, opacity;
  transition-delay: 0.5s, 0;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #1c627c;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  transition: opacity 0.5s;
  transition-property: height, opacity;
  transition-delay: 0.5s, 0;
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
.projectsLoaded.lds-elipsis,
.projectsLoaded.lds-ellipsis div {
  opacity: 0;
  height: 0;
  animation: none;
}
